import React, { useReducer, createContext, useMemo } from 'react';
import Fuse from 'fuse.js';

import useFilterParams from '../../hooks/useFilterParams';
import { mergeFilters, updateFilterParams, resetPagination, sortItems } from '../../lib/helpers';

import Widget from '../Widget/Widget';
import FormListCard from './FormListCard';

export const FormContext = createContext(null);

const { Provider: FormProvider } = FormContext,
  sortAlphabetical = (forms) => sortItems(forms, { sortKeys: ['entity', 'fieldFormCardTitle'] }),
  sortPublished = (forms) => sortItems(forms, { sortKeys: ['entity', 'created'], ascend: false }),
  sortTypeAlpha = 'alphabetical',
  individualKey = 'Individual forms',
  packetKey = 'Packet of forms',
  initialFilterTypes = { [individualKey]: false, [packetKey]: false };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_SORT':
      return { ...state, sortCb: payload === sortTypeAlpha ? sortAlphabetical : sortPublished, sortType: payload };
    case 'UPDATE_FILTER_SEARCH':
      return { ...state, filterSearch: { search: payload.search, results: payload.results } };
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    default:
      return state;
  }
};

const FormWidget = ({ forms, title, currentPath, files }) => {
  const initialState = {
      forms,
      sortCb: sortAlphabetical,
      sortType: sortTypeAlpha,
      filters: { Type: initialFilterTypes, Topic: {} },
      filterSearch: { results: forms.map(({ entity: { entityId } }) => entityId), search: '' },
    },
    [state, dispatch] = useReducer(reducer, initialState),
    fuseSearch = useMemo(
      () =>
        new Fuse(state.forms, {
          threshold: 0.4,
          keys: ['entity.fieldFormCardTitle', 'entity.fieldFormCardDescription.value', 'entity.fieldFormCardSerial'],
        }),
      []
    ),
    renderList = ({ entity, entity: { entityId } }) => <FormListCard data={entity} key={entityId} files={files} />,
    searchFilterConditions = (id) => state.filterSearch.results.includes(id),
    typeFilterConditions = (isPacket) =>
      (state.filters.Type[individualKey] && state.filters.Type[packetKey]) ||
      (!state.filters.Type[individualKey] && !state.filters.Type[packetKey]) ||
      (isPacket ? state.filters.Type[packetKey] : state.filters.Type[individualKey]),
    topicFilterConditions = (categories) => {
      const selectedTopics = Object.keys(state.filters.Topic).filter((k) => state.filters.Topic[k]);

      if (!selectedTopics.length) return true;

      for (const {
        entity: { name },
      } of categories) {
        if (selectedTopics.includes(name)) return true;
      }

      return false;
    };

  useFilterParams(state.filters, (payload) => dispatch({ type: 'UPDATE_FILTERS', payload }));

  const filterConditions = ({ entity: { fieldFormCardCategory, fieldFormCardPacket, entityId } }) => {
    const search = searchFilterConditions(entityId),
      type = typeFilterConditions(fieldFormCardPacket),
      topics = topicFilterConditions(fieldFormCardCategory);

    return search && type && topics;
  };

  const data = state.sortCb(forms).filter(filterConditions),
    onUpdateFilter = (type, { target: { checked, name } }) =>
      updateFilterParams(mergeFilters(state.filters, { type, key: name, value: checked })),
    onClearFilter = () => updateFilterParams({ Type: initialFilterTypes, Topic: {} }),
    onUpdateSearch = ({ target: { value: search } }) => {
      if (!search) return dispatch({ type: 'UPDATE_FILTER_SEARCH', payload: initialState.filterSearch });

      const results = fuseSearch.search(search).map(
        ({
          item: {
            entity: { entityId },
          },
        }) => entityId
      );

      dispatch({ type: 'UPDATE_FILTER_SEARCH', payload: { results, search } });
      resetPagination();
    },
    filterSelected = Object.values(state.filters).some((v) => Object.values(v).includes(true)),
    onUpdateSort = (sortType) => dispatch({ type: 'UPDATE_SORT', payload: sortType }),
    { filterSearch, filters: filterValues, sortType } = state;

  const filteredData = forms.reduce(
    (acc, { entity: { fieldFormCardCategory } }) => {
      for (let {
        entity: { name },
      } of fieldFormCardCategory) {
        if (!acc.Topic[name]) acc.Topic[name] = false;
      }

      return acc;
    },
    { ...initialState.filters }
  );

  return (
    <FormProvider
      value={{
        currentPath,
        data,
        searchLabel: `Search ${title}:`,
        filtersLabel: `Filter ${title}:`,
        renderList,
        sort: true,
        filterSearch: filterSearch.search,
        filteredData,
        filterValues,
        onUpdateSearch,
        onUpdateFilter,
        onClearFilter,
        onUpdateSort,
        filterSelected,
        searchPlaceholder: 'Use " " for exact match',
        sortType,
        title,
      }}
    >
      <Widget context={FormContext} />
    </FormProvider>
  );
};

export default FormWidget;
