import * as React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';

import { MainContainer, LeftContainer, PageContainer } from '../../common';
import Intro from '../../components/Intro/Intro';
import { styleVars } from '../../lib/helpers/style';

import FormWidget from '../../components/Form/FormWidget';

export const query = graphql`
  fragment formContent on Drupal_NodeForm {
    title
    fieldIntro {
      entity {
        ...introBlurb
      }
    }
    fieldFormCards {
      entity {
        ...authoredFormPacket
      }
    }
  }
  query($nid: String!, $pagePath: String!) {
    drupal {
      form: nodeById(id: $nid) {
        ...formContent
      }
    }
    sitePage(path: { eq: $pagePath }) {
      children {
        ... on File {
          id
          localURL
          publicURL
          url
        }
      }
    }
  }
`;
const Form = ({ data: { drupal, sitePage }, pageContext: { pagePath } }) => {
  const { form: node } = drupal,
    { children: files } = sitePage,
    { fieldIntro, fieldFormCards, title } = node;

  return (
    <>
      <MainContainer row className={`pt-8 pt-${styleVars.mobileBreakpoint}-9`}>
        <LeftContainer />
        <PageContainer>{fieldIntro && <Intro entity={fieldIntro} />}</PageContainer>
      </MainContainer>
      <Box padding={7} />
      <FormWidget currentPath={pagePath} title={title} forms={fieldFormCards} files={files} />
    </>
  );
};

export default Form;
