import React from 'react';
import { Card, Button } from 'react-bootstrap';
import ReactHTMLParser from 'react-html-parser';
import { Flex } from 'rebass';
import { saveAs } from 'file-saver';

import { Text, Link } from '../../common';
import Icon from '../../common/Icon/Icon';

import { formatFormPacketDate, transformHtmlWithLink } from '../../lib/helpers';

const FormListCard = ({
  data: {
    created,
    fieldFormCardSerial,
    fieldFormCardApplyOnlineLin,
    fieldFormCardAttachments,
    fieldFormCardTitle,
    fieldFormCardDescription: { processed },
  },
  files,
}) => {
  const downloadFiles = async (e) => {
    e.preventDefault();

    for (const {
      entity: {
        entityId,
        fieldMediaDocument: {
          entity: { filename, drupalURL },
        },
      },
    } of fieldFormCardAttachments) {
      const { localURL } = files.find(({ url }) => url === drupalURL);
      saveAs(encodeURI(localURL), filename);
    }
  };

  const firstFileUrl = encodeURI(
    files.find(({ url }) => url === fieldFormCardAttachments[0].entity.fieldMediaDocument.entity.drupalURL).localURL
  );

  return (
    <Card className="form-widget__card">
      <Card.Header className="border-0 pb-0">
        <Text fontSize={[3]} color="grey5">
          {`Form: ${fieldFormCardSerial}`}
        </Text>
        <Text fontSize={[3]} color="grey5">{`Published ${formatFormPacketDate(created)}`}</Text>
      </Card.Header>
      <Card.Body>
        <Text fontSize={[6]} fontWeight="bold">
          {fieldFormCardTitle}
        </Text>
        <Text as="div" className="mt-2">
          {ReactHTMLParser(processed, { transform: transformHtmlWithLink })}
        </Text>
      </Card.Body>
      <Card.Footer className="border-0 pt-0">
        <Flex>
          <>
            <Button href={firstFileUrl} onClick={downloadFiles} variant="link" className="form-widget__link">
              Download
            </Button>
            <Icon className="form-widget__download-icon" name="download" />
          </>
          {!!fieldFormCardApplyOnlineLin?.url && (
            <Link className="ml-4" href={fieldFormCardApplyOnlineLin.url.path}>
              {fieldFormCardApplyOnlineLin.title || 'Apply online'}
            </Link>
          )}
        </Flex>
      </Card.Footer>
    </Card>
  );
};

export default FormListCard;
